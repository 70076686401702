import { DialogService }                                     from 'aurelia-dialog';
import { bindable, inject }                                  from 'aurelia-framework';
import { I18N }                                              from 'aurelia-i18n';
import { PLATFORM }                                          from 'aurelia-pal';
import { BaseListViewModel }                                 from 'base-list-view-model';
import { ApprovedWorkingFormulaAdditionalInformationsModal } from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/additional-informations-modal';
import { FilterFormSchema }                                  from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/filter-form-schema';
import { ApprovedWorkingFormulasRepository }                 from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/services/repository';
import { LotInterventionsRepository }                        from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { AppContainer }                                      from 'resources/services/app-container';
import { Downloader }                                        from 'resources/services/downloader';

@inject(AppContainer, ApprovedWorkingFormulasRepository, I18N, Downloader, FilterFormSchema, DialogService, LotInterventionsRepository)
export class ListApprovedWorkingFormulas extends BaseListViewModel {

    listingId = 'approved-working-formulas-listing';

    @bindable headerTitle    = 'listing.bituminous-mixtures.approved-working-formulas';
    @bindable newRecordRoute = 'bituminous-mixtures.mixtures.approved-working-formulas.create';
    @bindable repository;
    @bindable datatable;
    @bindable row;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     * @param dialogService
     * @param lotInterventionsRepository
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService, lotInterventionsRepository) {
        super(appContainer, lotInterventionsRepository);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('bituminous-mixtures.mixtures.approved-working-formulas.index') && super.can([
            'bituminous-mixtures.mixtures.approved-working-formulas.manage',
            'bituminous-mixtures.mixtures.approved-working-formulas.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        if (this.appContainer.authenticatedUser.isLotClosed) {
            this.newRecordRoute = null;
        }

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        const selectedLotInformation = this.appContainer.authenticatedUser.getAllSelectedLotInformation();

        // Default filter by the current lot_intervention_id
        if (!this.filterModel.lot_intervention_id) {
            this.filterModel.lot_intervention_id = selectedLotInformation.lot_intervention_id;
        }

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('bituminous-mixtures.mixtures.approved-working-formulas.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.approved-working-formulas.manage', 'bituminous-mixtures.mixtures.approved-working-formulas.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('bituminous-mixtures.mixtures.approved-working-formulas.edit', { id: row.id }),
                visible: (row) => row.user_access.access && !row.is_validated && this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.approved-working-formulas.manage', 'bituminous-mixtures.mixtures.approved-working-formulas.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => row.user_access.destroy && this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.approved-working-formulas.manage', 'bituminous-mixtures.mixtures.approved-working-formulas.delete']),
            },
            options:            [
                {
                    label:   'form.button.validate-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => this.repository.validateSelected(this.datatable.instance.selectedRows).then((response) => {
                        this.appContainer.notifier.responseNotice(response);
                        this.datatable.instance.reload();
                    })),
                    visible: () => this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.approved-working-formulas.manage', 'bituminous-mixtures.mixtures.approved-working-formulas.validate']),
                },
                {
                    label:   'form.button.invalidate-selected',
                    icon:    'icon-cross',
                    action:  () => this.ensureSelectedRows(() => this.repository.invalidateSelected(this.datatable.instance.selectedRows).then((response) => {
                        this.appContainer.notifier.responseNotice(response);
                        this.datatable.instance.reload();
                    })),
                    visible: () => this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.approved-working-formulas.manage', 'bituminous-mixtures.mixtures.approved-working-formulas.invalidate']),
                },
                {
                    label:   'form.button.export-zip',
                    icon:    'icon-file-zip',
                    action:  () => this.exportZip(),
                    visible: () => this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.approved-working-formulas.manage', 'bituminous-mixtures.mixtures.approved-working-formulas.view']),
                },
            ],
            actions:            [
                {
                    icon:    'icon-cross',
                    action:  (row) => this.invalidate(row),
                    tooltip: 'form.button.invalidate',
                    visible: (row) => row.user_access.invalidate && row.is_validated && !this.appContainer.authenticatedUser.isBuilder && this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.approved-working-formulas.manage', 'bituminous-mixtures.mixtures.approved-working-formulas.invalidate']) && this.appContainer.authenticatedUser.inSameLotIntervention(row.lot_intervention_id),
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.validate(row),
                    tooltip: 'form.button.validate',
                    visible: (row) => row.user_access.validate && !row.is_validated && this.appContainer.authenticatedUser.can(['bituminous-mixtures.mixtures.approved-working-formulas.manage', 'bituminous-mixtures.mixtures.approved-working-formulas.validate']) && this.appContainer.authenticatedUser.inSameLotIntervention(row.lot_intervention_id),
                },
                {
                    icon:    'icon-info3',
                    action:  (row) => this.openModal(row.id, ApprovedWorkingFormulaAdditionalInformationsModal),
                    tooltip: 'form.button.view-additional-info',
                },
            ],
            actionsContextMenu: true,
            selectable:         true,
            destroySelected:    true,
            sorting:            [
                {
                    column:    0,
                    direction: 'desc',
                },
                {
                    column:    6,
                    direction: 'desc',
                },
            ],
            columns:            [
                {
                    data:  'number',
                    name:  'bm_approved_working_formulas.number',
                    title: 'form.field.fta-number',
                },
                {
                    data:  'concession_description',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:  'highway_designation',
                    name:  'highway_translations.designation',
                    title: 'form.field.highway',
                },
                {
                    data:    'intervention_type_designation',
                    name:    'intervention_type_translations.designation',
                    title:   'form.field.intervention-type',
                    display: false,
                },
                {
                    data:  'lot_intervention_designation',
                    name:  'lot_intervention_translations.designation',
                    title: 'form.field.lot-intervention',
                },
                {
                    data:  'date',
                    name:  'bm_approved_working_formulas.date',
                    title: 'form.field.date',
                },
                {
                    data:  'approval_date',
                    name:  'bm_approved_working_formulas.approval_date',
                    title: 'form.field.approval-date',
                },
                {
                    data:  'formulation_study_number',
                    name:  'bm_formulation_studies.number',
                    title: 'form.field.study-number',
                },
                {
                    data:  'tcpf_numbers',
                    name:  'bm_manufacturing_plant_transpositions.number',
                    title: 'form.field.tcpf-numbers',
                },
                {
                    data:  'material_type_name',
                    name:  'material_type_translations.name',
                    title: 'form.field.material-type',
                },
                {
                    data:  'layer_name',
                    name:  'bm_layer_translations.name',
                    title: 'form.field.layer-name',
                },
                {
                    data:  'application_name',
                    name:  'bituminous_application_translations.name',
                    title: 'form.field.application',
                },
                {
                    data:    'observations',
                    name:    'bm_approved_working_formulas.observations',
                    title:   'form.field.observations',
                    display: false,
                },
                {
                    data:       'entity_name',
                    name:       'entity_translations.name',
                    title:      'form.field.entity',
                    searchable: false,
                    orderable:  false,
                    type:       'entityTeam',
                },
                {
                    data:  'created_by',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'bm_approved_working_formulas.created_at',
                    title:           'form.field.created-at',
                    display:         false,
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:          'is_validated',
                    name:          'is_validated',
                    title:         'form.field.validated',
                    type:          'custom-cell',
                    fullDataModel: 'validator',
                    viewModel:     PLATFORM.moduleName('modules/core/custom-listing-cells/checkmark/index'),
                    orderable:     false,
                    searchable:    false,
                },
                {
                    data:            'cover_page',
                    name:            'cover_page',
                    title:           'form.field.cover-page',
                    type:            'custom-cell',
                    filesRepository: ApprovedWorkingFormulasRepository,
                    alwaysVisible:   true,
                    action:          (row) => this.exportToPdf(row),
                    value:           (row) => row.file_id,
                    icon:            'icon-file-pdf',
                    viewModel:       PLATFORM.moduleName('modules/core/custom-listing-cells/file/index'),
                    orderable:       false,
                    searchable:      false,
                },
                {
                    data:       'additional_info',
                    name:       'additional_info',
                    title:      'form.title.additional-info',
                    type:       'custom-cell',
                    icon:       'icon-info3',
                    show:       (row) => row.has_additional_information,
                    action:     (row) => this.openModal(row.id, ApprovedWorkingFormulaAdditionalInformationsModal),
                    viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                    orderable:  false,
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Validates a given record
     *
     * @param row
     */
    validate(row) {
        this.repository
            .validate(row.id)
            .then(response => {
                this.appContainer.notifier.responseNotice(response);
                this.datatable.instance.reload();
            });
    }

    /**
     * Invalidates a given record
     *
     * @param row
     */
    invalidate(row) {
        this.repository
            .invalidate(row.id)
            .then(response => {
                this.appContainer.notifier.responseNotice(response);
                this.datatable.instance.reload();
            });
    }

    /**
     * Exports to pdf
     *
     * @param row
     * @return {*}
     */
    exportToPdf(row) {
        return this.repository.exportToPdf(row.id).then(response => this.downloader.download(response, this.appContainer.i18n.tr('form.field.cover-page'), 'pdf'));
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.appContainer.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }
}
